import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { Box, Container, Grid, IconButton, Paper } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import useList from "../../context-utils/ListContext";
import { StoreContext } from "../../context/StoreContext";
import updateField from "../../functions/database-functions/upadateField";
import { EMPTY } from "../../settings/settings";
import { GridContainer, GridDivider, GridFlexBox, Name, Title } from "../../themes/themes";
import { checkItem } from "./checkItem";
import createDropDownList from "./createDropDownList";
import getMaxWidth from "./getMaxWidth";
import loadPurchaseOrders from "./loadPurchaseOrders";
import convertDate from "../../functions/common-functions/convertDate";
import convertToNumber from "../../functions/common-functions/convertToNumber";

const PURCHASE_ORDER_ID = [
    { id: "po_date", label: "po_date", width: "100px", type: "date" },
    { id: "po_number", label: "po_number", width: "100px" },
    { id: "vendor", label: "vendor", width: "100px" },
    { id: "pi", label: "pi", width: "100px" },
    { id: "customer", label: "customer", width: "100px" },
    { id: "qty_kgs", label: "qty_kgs", type: "number", width: "100px" },
    { id: "up_usd", label: "up_usd", type: "number", width: "100px" },
    { id: "etd_date", label: "etd_date", width: "100px" },
    { id: "eta_date", label: "eta_date", width: "100px" },
    { id: "etd2_date", label: "etd2_date", width: "100px", type: "date" },
    { id: "eta2_date", label: "eta2_date", width: "100px", type: "date" },
    { id: "ba_allocation_date", label: "ba_allocation_date", width: "100px", type: "date" },
    { id: "grn_date", label: "grn_date", width: "100px" },
    { id: "currency_rate", label: "currency_rate", type: "number", width: "100px" },
    { id: "total_usd", label: "total_usd", type: "number", width: "100px" },
    { id: "total_purchase_rm", label: "total_purchase_rm", type: "number", width: "100px" },
    { id: "amount_paid", label: "amount_paid", type: "number", width: "100px" },
    { id: "balance_due", label: "balance_due", type: "number", width: "100px" },
    { id: "comments", label: "comments", width: "100px" },
    { id: "status", label: "status", width: "100px" }
];

const COLLECTION_ID = "PurchaseOrders";

export default function PurchaseOrdersPage() {
    const [header, setHeader] = useState(PURCHASE_ORDER_ID);
    const [search, setSearch] = useState({ id: "", value: "" });
    const [edit, setEdit] = useState({ headerId: "", value: "", poId: "" });

    const { purchaseOrders, setPurchaseOrders, addUnsubscribeStore } = useContext(StoreContext);

    useEffect(() => {
        if (!purchaseOrders) {
            const unsubscribe = loadPurchaseOrders(setPurchaseOrders);
            addUnsubscribeStore(unsubscribe);
        }
    }, []);

    useEffect(() => {
        handleAutoWidth();
    }, [purchaseOrders]);

    const handleAutoWidth = () => {
        if (!purchaseOrders) return;
        const newHeader = [...header];
        newHeader.forEach((item) => {
            const maxWidth = getMaxWidth(purchaseOrders, item.id);
            item.width = maxWidth;
        });
        setHeader(newHeader);
    };

    const list = useList();
    const handleClick = async (id) => {
        console.log(id);
        // SEARCH OR SORT
        const dropDownList = createDropDownList(purchaseOrders, id);
        const response = await list(dropDownList, "Search", "name", "face", false, true);
        if (response) {
            setSearch({ id, value: response.name });
        }
    };

    const handleClickEdit = ({ headerId, poId }) => {
        const purchaseOrder = purchaseOrders.find((po) => po.id === poId);
        let value = checkItem(purchaseOrder[headerId]);
        const { type } = header.find((item) => item.id === headerId);
        if (type === "number") {
            value = purchaseOrder[headerId];
        }
        setEdit({ headerId, poId, value, type: type || "text" });
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setEdit({ ...edit, value });
    };

    const handleBlur = async () => {
        console.log("Blur");
        setEdit({ headerId: "", poId: "", value: "", type: "" });
    };

    const handleSave = async () => {
        console.log("save");
        const docId = edit.poId;
        const collectionId = COLLECTION_ID;
        const field = edit.headerId;
        let value = edit.value;
        const oldPO = purchaseOrders.find((po) => po.id === docId);

        // skip if no change
        if (value === checkItem(oldPO[field])) {
            setEdit({ headerId: "", poId: "", value: "" });
            return;
        }

        // check type
        if (edit.type === "date") {
            value = convertDate(value);
        }
        if (edit.type === "number") {
            value = convertToNumber(value);
        }
        await updateField({ docId, collectionId, field, value });
        setEdit({ headerId: "", poId: "", value: "", type: "" });
    };

    const handleKeyDown = (e, { i, j }) => {
        const { key } = e;
        if (key === "Tab") {
            e.preventDefault();
            if (j !== header.length - 1) {
                setEdit({ headerId: header[j + 1].id, poId: displayPO[i].id, value: displayPO[i][header[j + 1].id] });
            } else {
                if (i !== displayPO.length - 1) {
                    setEdit({
                        headerId: header[0].id,
                        poId: displayPO[i + 1].id,
                        value: displayPO[i + 1][header[0].id]
                    });
                }
            }
        }
        if (key === "Enter") {
            e.preventDefault();
            handleSave();
        }
        if (key === "Escape") {
            e.preventDefault();
            setEdit({ headerId: "", poId: "", value: "" });
        }
    };

    const displayPO = useMemo(() => {
        let displayPO = purchaseOrders;
        if (search.id && search.value) {
            if (search.value === EMPTY) {
                displayPO = displayPO.filter((po) => checkItem(po[search.id]) === "");
            } else {
                displayPO = displayPO.filter((po) => checkItem(po[search.id]) === search.value);
            }
        }
        return displayPO;
    }, [search, purchaseOrders]);

    return (
        <Container maxWidth="none">
            <GridContainer>
                <GridFlexBox xs={12} md={5} fs>
                    {search.id && (
                        <>
                            <Name ct>Search ID: </Name>
                            <Name ct bold pl1>
                                {search.id}
                            </Name>
                            <Name ct pl1>
                                Search value:{" "}
                            </Name>
                            <Name ct bold pl1>
                                {search.value}
                            </Name>
                            <IconButton size="small" onClick={() => setSearch({ id: "", value: "" })}>
                                <HighlightOffIcon />
                            </IconButton>
                        </>
                    )}
                </GridFlexBox>
                <GridFlexBox xs={12} md={7} fs>
                    <Title>Purchase Orders</Title>
                </GridFlexBox>
                <GridDivider />
                <Paper style={{ overflow: "auto", height: "80vh" }} variant="outlined">
                    {/* HEADERS */}
                    <Grid item xs={12} flexWrap="nowrap">
                        <Box display={"flex"}>
                            {header?.map((item) => (
                                <Box
                                    sx={{
                                        width: item.width || "100px",
                                        padding: "4px",
                                        border: "1px solid grey",
                                        borderRight: "none"
                                    }}
                                    key={item.id}
                                    flexShrink={0}
                                    alignSelf={"stretch"}
                                >
                                    <GridFlexBox>
                                        <Name bold cp onClick={() => handleClick(item.id)}>
                                            {item.label}
                                        </Name>
                                    </GridFlexBox>
                                </Box>
                            ))}
                        </Box>
                    </Grid>

                    {/* ROWS OF DATA */}
                    {displayPO?.map((po, i) => (
                        <Grid item xs={12} flexWrap="nowrap" key={po.id}>
                            <Box display={"flex"} alignItems="center">
                                {header?.map((item, j) => (
                                    <Box
                                        sx={{
                                            width: item.width || "100px",
                                            padding: "4px",
                                            border: "1px solid grey",
                                            borderRight: "none",
                                            borderTop: "none"
                                        }}
                                        alignSelf={"stretch"}
                                        key={item.id}
                                        flexShrink={0}
                                    >
                                        {isEdit(item.id, po.id, edit) ? (
                                            <GridFlexBox fs>
                                                <Paper style={{ zIndex: 20000 }}>
                                                    <input
                                                        value={edit.value || ""}
                                                        type={edit.type === "date" ? "date" : "text"}
                                                        style={{
                                                            padding: "8px",
                                                            border: 0,
                                                            fontFamily: "IBM Plex Sans",
                                                            zIndex: 1000
                                                        }}
                                                        onChange={handleChange}
                                                        autoFocus={true}
                                                        onBlur={handleBlur}
                                                        onKeyDown={(e) => handleKeyDown(e, { i, j })}
                                                    />
                                                    <IconButton onMouseDown={handleSave} style={{ zIndex: 10000 }}>
                                                        <SaveOutlinedIcon style={{ zIndex: 10000 }} />
                                                    </IconButton>
                                                </Paper>
                                            </GridFlexBox>
                                        ) : (
                                            <GridFlexBox
                                                fe={item.type === "number" && true}
                                                onClick={() => handleClickEdit({ headerId: item.id, poId: po.id })}
                                            >
                                                <Name nowrap fontFamily={"IBM Plex Sans"} ct>
                                                    {checkItem(po[item.id]) || "-"}
                                                </Name>
                                            </GridFlexBox>
                                        )}
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                    ))}
                </Paper>
            </GridContainer>
        </Container>
    );
}

const isEdit = (headerId, poId, edit) => {
    if (headerId === edit.headerId && poId === edit.poId) return true;
    return false;
};
