import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import { notification } from "../../utils-functions/notification";

export default async function resetPassword(email) {
    if (email) {
        email = email.trim().toLowerCase();
    }

    try {
        const userRef = doc(db, "users", email);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
            const emailCollectionRef = collection(db, "resetPassword");
            await addDoc(emailCollectionRef, { email: email });
            console.log("Reset email sent");
            notification("Reset password", "Email sent. Please check your email", "warning");
            return true;
        } else {
            notification("Cannot find email", "Please double check your email", "warning");
            return false;
        }
    } catch (err) {
        console.log(err);
    }
}
