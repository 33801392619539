import { blue, green, orange } from "@mui/material/colors";

export const MENU_ITEMS = [
    { action: "Home", url: "/", icon: "home" },
    { action: "Divider", url: "/", icon: "" },
    { action: "Upload Data Page", url: "/upload-data-page", icon: "cloud_upload_outlined" },
    { action: "Divider", url: "/", icon: "" },
    { action: "Sign Out", url: "/sign-out", icon: "logout" },
    { action: "Divider", url: "/", icon: "" },
    { action: "Test", url: "/test-page", icon: "biotech", role: "Super Admin", color: green[500] }
];

export const MENU_ITEMS_SIGNED_OUT = [
    { action: "Home", url: "/", icon: "home" },
    { action: "Divider", url: "/", icon: "" },
    { action: "Sign In", url: "/sign-in", icon: "login" }
];

export const COPYRIGHT_LINK = "https://www.djcsystem.com";

export const WEBSITE_NAME = "www.djcsystem.com";

export const APP_TITLE = "NNC Leads App";

export const SECRET_CODE = "djc123";

export const HOMEPAGE_ITEMS = [
    { description: "Upload Data Page", title: "Upload Data", color: blue[500], link: "/upload-data-page" },
    { description: "View Purchase Orders", title: "Purchase Orders", color: orange[500], link: "/purchase-order-page" }
];

export const HOME = "HOME";
export const ROWS_PER_PAGE = 50;
export const EMPTY = "**empty**";
