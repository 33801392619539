import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../firebase/firebase-utils";

export default function savePurchaseOrders(data, setMessage) {
    try {
        const collectionRef = collection(db, "PurchaseOrders");

        data.map((item) => {
            const promise = addDoc(collectionRef, item);
            promise.then((ref) => {
                console.log("Purchase Order added with ID: ", ref.id);
                const msg = `Purchase Order added with ID: ${ref.id}`;
                setMessage((prev) => (prev ? [...prev, msg] : [msg]));
            });
        });
        console.log("done");
    } catch (err) {
        console.log(err);
    }
}
