import React, { createContext, useEffect, useState } from "react";

export const LeadsContext = createContext();

const MAX_NUMBER = 250;

export const LeadsProvider = ({ children }) => {
    const [sourceLeads, setSourceLeads] = useState();
    const [assignedLeads, setAssignedLeads] = useState();
    const [folderLeads, setFolderLeads] = useState();
    const [unsubscribeFolderLeads, setUnsubscribeFolderLeads] = useState();
    const [unsubscribeSourceLeads, setUnsubscribeSourceLeads] = useState();
    const [unsubscribeAssignedLeads, setUnsubscribeAssignedLeads] = useState();
    const [maxNumberSourceLeads, setMaxNumberSourceLeads] = useState(MAX_NUMBER);
    const [maxNumberAssignedLeads, setMaxNumberAssignedLeads] = useState(MAX_NUMBER);

    useEffect(() => {
        window.addEventListener("beforeunload", handleUnload);
    }, []);

    const handleUnload = () => {
        unsubscribeFolderLeads?.();
        unsubscribeSourceLeads?.();
        unsubscribeAssignedLeads?.();
    };

    return (
        <LeadsContext.Provider
            value={{
                sourceLeads,
                setSourceLeads,
                assignedLeads,
                setAssignedLeads,
                folderLeads,
                setFolderLeads,
                unsubscribeSourceLeads,
                setUnsubscribeSourceLeads,
                unsubscribeAssignedLeads,
                setUnsubscribeAssignedLeads,
                unsubscribeFolderLeads,
                setUnsubscribeFolderLeads,
                maxNumberSourceLeads,
                setMaxNumberSourceLeads,
                maxNumberAssignedLeads,
                setMaxNumberAssignedLeads,
                MAX_NUMBER
            }}
        >
            {children}
        </LeadsContext.Provider>
    );
};
