import { TextField } from "@mui/material";
import { Container } from "@mui/system";
import { useState } from "react";
import resetPassword from "../../functions/users-functions/resetPassword";
import { ButtonC, GridContainer, GridDivider, GridFlexBox, Name, Title } from "../../themes/themes";

export default function ForgotPasswordPage() {
    const [email, setEmail] = useState("");
    const [sent, setSent] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await resetPassword(email);
        if (response) {
            setSent(true);
        }
    };

    return (
        <Container maxWidth="sm">
            <GridContainer>
                <GridFlexBox>
                    <Title>Reset Password</Title>
                </GridFlexBox>
                <GridDivider />
                {sent && (
                    <GridFlexBox m1>
                        <Name>Please check your email for the password reset link</Name>
                    </GridFlexBox>
                )}
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                    <GridFlexBox m1>
                        <TextField
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            label="Enter your Email"
                            fullWidth
                            disabled={sent}
                        />
                    </GridFlexBox>
                    <GridFlexBox m1>
                        <ButtonC submit fullWidth disabled={sent}>
                            Submit
                        </ButtonC>
                    </GridFlexBox>
                </form>
            </GridContainer>
        </Container>
    );
}
