import { getAuth, signOut as authSignOut } from "firebase/auth";

export async function signOut() {
    try {
        // Sign out from Firebase
        const auth = getAuth();
        await authSignOut(auth);

        // Clear local storage "user" object
        localStorage.removeItem("user");
        localStorage.removeItem("authUser");
    } catch (err) {
        console.log(err.message);
    }
}
