import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default async function updateField({ docId, collectionId, field, value }) {
    try {
        const docRef = doc(db, collectionId, docId);
        await updateDoc(docRef, { [field]: value });
        console.log("Document successfully updated!");
    } catch (error) {
        console.log(error);
    }
}
