import { Button, Container } from "@mui/material";
import { useState } from "react";
import TypingEffect from "../../Components/Common/TypingEffect";
import { GridContainer, GridDivider, GridFlexBox, Title } from "../../themes/themes";
import randomQuote from "./randomQuote";

export default function TestPage() {
    const [message, setMessage] = useState();
    const [clear, setClear] = useState(0);

    const handleDone = () => {};

    const handleClick = () => {
        const promises = generatePromises();
        console.log(promises);
        promises.forEach((promise) => {
            promise.then((res) => {
                setMessage((prev) => (prev ? [...prev, res] : [res]));
            });
        });
    };

    const handleGenerate = () => {
        setClear((i) => i + 1);
        const quotes = randomQuote();
        const quotesArray = quotes.map((quote) => quote.quote);
        setMessage((prev) => (prev ? [...prev, ...quotesArray] : [...quotesArray]));
    };

    return (
        <Container>
            <GridContainer>
                <GridFlexBox>
                    <Title>Test Page</Title>
                </GridFlexBox>
                <GridDivider />
                <GridFlexBox>
                    <TypingEffect sentences={message} setDone={handleDone} speed={10} clear={clear} />
                </GridFlexBox>
                <GridFlexBox gap={2}>
                    <Button variant="contained" color="primary" onClick={handleClick}>
                        Generate
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleGenerate}>
                        Generate Quotes
                    </Button>
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
}

const generatePromises = () => {
    const promiseMessages = [1, 3, 0.05, 4, 2, 6].map((item) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(`item: ${item}`);
            }, item * 500);
        });
    });
    return promiseMessages;
};
