import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default async function editField(object, key, value, collectionId) {
    try {
        const docRef = doc(db, collectionId, object.id);
        await updateDoc(docRef, { [key]: value });
        console.log(object.id, "updated: " + key + " : " + value);
    } catch (error) {
        console.log(error);
    }
}
