export default function formatDate(d, time = false) {
    let month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours(),
        minute = d.getMinutes();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    const formattedDate = [year, month, day].join("-");
    if (hour.toString().length < 2) hour = "0" + hour;
    if (minute.toString().length < 2) minute = "0" + minute;
    const formattedTime = [hour, minute].join(":");

    if (!time) {
        return formattedDate;
    } else {
        return `${formattedDate}T${formattedTime}`;
    }
}
