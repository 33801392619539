export default function randomQuote() {
    return motivationalQuotes;
}

const motivationalQuotes = [
    {
        id: 1,
        quote: "Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle."
    },
    {
        id: 2,
        quote: "Success is not final, failure is not fatal: It is the courage to continue that counts."
    },
    {
        id: 3,
        quote: "The greatest glory in living lies not in never falling, but in rising every time we fall."
    },
    {
        id: 4,
        quote: "Don't watch the clock; do what it does. Keep going."
    },
    {
        id: 5,
        quote: "Believe you can and you're halfway there."
    },
    {
        id: 6,
        quote: "The only way to do great work is to love what you do."
    },
    {
        id: 7,
        quote: "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful."
    },
    {
        id: 8,
        quote: "Believe in your dreams and they may come true; believe in yourself and they will come true."
    },
    {
        id: 9,
        quote: "The future belongs to those who believe in the beauty of their dreams."
    },
    {
        id: 10,
        quote: "Your only limit is the one you set for yourself."
    }
];
