import { Container, CssBaseline, ThemeProvider } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { Route, Routes } from "react-router-dom";
import BouncingBalls from "./Components/Common/BouncingBalls";
import Menu from "./Components/Common/Menu";
import { ConfirmationProvider } from "./context-utils/ConfirmationContext";
import { InputProvider } from "./context-utils/InputContext";
import { ListProvider } from "./context-utils/ListContext";
import { NotificationProvider } from "./context-utils/NotificationContext";
import { AuthContext } from "./context/AuthContext";
import ForgotPasswordPage from "./pages/CommonPages/ForgotPasswordPage";
import HomePage from "./pages/CommonPages/HomePage";
import LandingPage from "./pages/CommonPages/LandingPage";
import NotFoundPage from "./pages/CommonPages/NotFoundPage";
import SignInPage from "./pages/CommonPages/SignInPage";
import SignOutPage from "./pages/CommonPages/SignOutPage";
import SignUpPage from "./pages/CommonPages/SignUpPage";
import UploadDataPage from "./pages/UploadDataPage/UploadDataPage";
import UserCardPage from "./pages/UserCardPage/UserCardPage";
import { ColorModeContext, useMode } from "./theme";
import TestPage from "./pages/TestPage/TestPage";
import PurchaseOrdersPage from "./pages/PurchaseOrdersPage/PurchaseOrdersPage";

// Logged In condition
function withUser() {
    return (
        <Routes>
            <Route index element={<HomePage />} />
            <Route exact path="/user-card-page" element={<UserCardPage />} />
            <Route exact path="/upload-data-page" element={<UploadDataPage />} />
            <Route exact path="/purchase-order-page" element={<PurchaseOrdersPage />} />
            <Route exact path="/test-page" element={<TestPage />} />
            <Route exact path="/sign-out" element={<SignOutPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
}

// Logged out condition
function noUser() {
    return (
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route exact path="/sign-up" element={<SignUpPage />} />
            <Route exact path="/sign-in" element={<SignInPage />} />
            <Route path="*" element={<LandingPage />} />
            <Route path="forgot-password-page" element={<ForgotPasswordPage />} />
        </Routes>
    );
}

function App() {
    const { user } = useContext(AuthContext);
    const [theme, colorMode] = useMode();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const displayComponent = useMemo(() => {
        const displayComponent = user ? withUser() : noUser();
        return displayComponent;
    }, [user]);

    return (
        <div>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <NotificationProvider>
                        <ConfirmationProvider>
                            <ListProvider>
                                <InputProvider>
                                    <CssBaseline />
                                    <div className="app">
                                        <main className="content">
                                            <Menu user={user} />
                                            <Container maxWidth="md">{loading && <BouncingBalls />}</Container>
                                            {!loading && displayComponent}
                                        </main>
                                    </div>
                                </InputProvider>
                            </ListProvider>
                        </ConfirmationProvider>
                    </NotificationProvider>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </div>
    );
}

export default App;
