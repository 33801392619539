import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Divider, Icon, ListItemIcon, ListItemText } from "@mui/material";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

export default function MenuDropdown({ handleClose, menuDropDown, anchorEl }) {
    const { user } = useContext(AuthContext);

    const display = (item) => {
        if (!item.role) {
            return true;
        } else {
            if (user && user.role === "Super Admin") {
                return true;
            } else {
                if (user && item.role === user.role) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    };

    return (
        <div>
            <Menu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {menuDropDown.map(
                    (item, i) =>
                        display(item) &&
                        (item.action === "Divider" ? (
                            <Divider key={i} />
                        ) : (
                            <MenuItem onClick={handleClose} data-index={item.action} key={i}>
                                <ListItemIcon>
                                    <Icon fontSize="small" sx={{ color: item.color || "inherit" }}>
                                        {item.icon}
                                    </Icon>
                                </ListItemIcon>
                                <ListItemText primary={item.action} sx={{ color: item.color || "inherit" }} />
                            </MenuItem>
                        ))
                )}
            </Menu>
        </div>
    );
}
