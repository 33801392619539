export default function removeDuplicated(itemsList, key) {
    if (key) {
        const unique = itemsList
            .map((e) => e[key])
            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)
            // eliminate the dead keys & store unique objects
            .filter((e) => itemsList[e])
            .map((e) => itemsList[e]);
        return unique;
    } else {
        const unique = itemsList
            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)
            // eliminate the dead keys & store unique objects
            .filter((e) => itemsList[e])
            .map((e) => itemsList[e]);
        return unique;
    }
}
