import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom/client";
import { ReactNotifications } from "react-notifications-component";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import { LeadsProvider } from "./context/LeadsContext";
import { StateProvider } from "./context/StateContext";
import { StoreProvider } from "./context/StoreContext";
import { UsersProvider } from "./context/UsersContext";
import "./index.css";

const host = window.location.hostname;
if (host !== "localhost") {
    Sentry.init({
        dsn: "https://9f398ea5ace946b58e843e77427fdf21@o4504715897470976.ingest.sentry.io/4504737265418240",
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.1,
    });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.Fragment>
        <ReactNotifications />
        <BrowserRouter>
            <StateProvider>
                <UsersProvider>
                    <StoreProvider>
                        <LeadsProvider>
                            <AuthProvider>
                                <App />
                            </AuthProvider>
                        </LeadsProvider>
                    </StoreProvider>
                </UsersProvider>
            </StateProvider>
        </BrowserRouter>
    </React.Fragment>
);
