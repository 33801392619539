import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../../firebase/firebase-utils";
import { notification } from "../../utils-functions/notification";

export default async function signUp(firstName, lastName, email, phone, password, setUser, setAuthUser) {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const authUser = userCredential.user;

        const user = await createNewUser(firstName, lastName, email, phone, setUser);
        setAuthUser(authUser);
        console.log(user, " is created");
        return true;
    } catch (err) {
        console.log(err);
        let message = err.message;

        if (err.code === "auth/email-already-in-use") {
            message = "This email is already in use. Please use another email";
        }
        notification("Error", message, "warning");
        return false;
    }
}

const createNewUser = async (firstName, lastName, email, phone, setUser) => {
    try {
        const userRef = doc(db, "users", email);
        const userData = {
            email,
            phone,
            firstName,
            lastName,
            displayName: firstName + " " + lastName,
            id: email,
        };
        console.log(userRef);
        await setDoc(userRef, userData, { merge: true });
        setUser(userData);
        console.log("Firebase user created", firstName);
        return userData;
    } catch (err) {
        console.log(err);
    }
};
