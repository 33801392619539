import convertDate from "../../functions/common-functions/convertDate";
import formatDate from "../../functions/common-functions/formatDate";
import formatNumber from "../../functions/common-functions/formatNumber";

export function checkItem(item) {
    if (!item) return "";
    switch (typeof item) {
        case "string":
            return item;
        case "object": {
            let date = convertDate(item);
            return formatDate(date);
        }
        case "number": {
            return formatNumber(item);
        }
        default:
            return item;
    }
}
