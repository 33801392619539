import { checkItem } from "./checkItem";

export default function getMaxWidth(purchaseOrders, id) {
    let maxWidth = 0;
    purchaseOrders.forEach((purchaseOrder) => {
        const item = checkItem(purchaseOrder[id]).trim();
        if (item) {
            const width = item.length * 9;
            if (width > maxWidth) {
                maxWidth = width;
            }
        }
    });
    if (maxWidth > 150) maxWidth = 150;
    if (maxWidth < 50) maxWidth = 70;
    return maxWidth;
}
