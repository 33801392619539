import { Container } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import TypingEffect from "../../Components/Common/TypingEffect";
import useNotification from "../../context-utils/NotificationContext";
import { AuthContext } from "../../context/AuthContext";
import convertDate from "../../functions/common-functions/convertDate";
import { GridContainer, GridDivider, GridFlexBox, Title } from "../../themes/themes";
import UploadExcel from "./UploadExcel";
import convertExcelDate from "./functions/convertExcelDate";
import savePurchaseOrders from "./functions/savePurchaseOrders";

const REQUIRED_FIELDS = [
    { id: "po_date", label: "po_date" },
    { id: "po_number", label: "po_number" },
    { id: "vendor", label: "vendor" },
    { id: "pi", label: "pi" },
    { id: "customer", label: "customer" },
    { id: "qty_kgs", label: "qty_kgs" },
    { id: "up_usd", label: "up_usd" },
    { id: "etd_date", label: "etd_date" },
    { id: "eta_date", label: "eta_date" },
    { id: "etd2_date", label: "etd2_date" },
    { id: "eta2_date", label: "eta2_date" },
    { id: "ba_allocation_date", label: "ba_allocation_date" },
    { id: "grn_date", label: "grn_date" },
    { id: "currency_rate", label: "currency_rate" },
    { id: "total_usd", label: "total_usd" },
    { id: "total_purchase_usd", label: "total_purchase_usd" },
    { id: "amount_paid", label: "amount_paid" },
    { id: "balance_due", label: "balance_due" },
    { id: "comments", label: "comments" },
    { id: "status", label: "status" }
];

export default function UploadDataPage() {
    const [requiredFields, setRequiredFields] = useState(REQUIRED_FIELDS);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [message, setMessage] = useState();

    const { user } = useContext(AuthContext);
    const notify = useNotification();

    const handleUpload = async (data) => {
        let rows = convertData(data);
        rows = rows.map((row) => ({ ...row, createdBy: user.id }));
        setLoading(true);
        setMessage();
        setSuccess(false);
        savePurchaseOrders(rows, setMessage);
    };

    useEffect(() => {
        if (success) {
            setLoading(false);
            notify("Data uploaded successfully", "success");
        }
    }, [success]);

    return (
        <Container maxWidth="none">
            <GridContainer>
                <GridFlexBox>
                    <Title>Upload Data</Title>
                </GridFlexBox>
                <GridDivider />
                <UploadExcel
                    requiredFields={requiredFields}
                    setRequiredFields={setRequiredFields}
                    handleUpload={handleUpload}
                    loading={loading}
                    success={success}
                    setSuccess={setSuccess}
                    rows={rows}
                    columns={columns}
                    setRows={setRows}
                    setColumns={setColumns}
                />
                <GridFlexBox mt1>{message && <TypingEffect sentences={message} setDone={setSuccess} />}</GridFlexBox>
            </GridContainer>
        </Container>
    );
}

const convertData = (data) => {
    const rows = data.map((row) => {
        const newRow = {};
        Object.keys(row).forEach((key) => {
            if (key.toLowerCase().includes("date")) {
                if (typeof row[key] === "number") {
                    let date = convertExcelDate(row[key]);
                    date = convertDate(date);
                    newRow[key] = date;
                } else {
                    newRow[key] = row[key];
                }
            } else {
                newRow[key] = row[key];
            }
        });
        return newRow;
    });
    return rows;
};
