import React, { useEffect, useState } from "react";

export const StoreContext = React.createContext();

export const StoreProvider = ({ children }) => {
    const [store, setStore] = useState({});
    const [purchaseOrders, setPurchaseOrders] = useState();
    const [unsubscribeStore, setUnsubscribeStore] = useState();

    useEffect(() => {
        return () => clearStore();
    }, [unsubscribeStore]);

    const addUnsubscribeStore = (fn) => {
        if (unsubscribeStore) {
            let array = [...unsubscribeStore];
            array.push(fn);
            setUnsubscribeStore(array);
        } else {
            setUnsubscribeStore([fn]);
        }
    };

    const clearStore = () => {
        setStore();

        if (unsubscribeStore) {
            unsubscribeStore.forEach((fn) => fn());
        }
    };

    return (
        <StoreContext.Provider
            value={{
                store,
                setStore,
                unsubscribeStore,
                setUnsubscribeStore,
                clearStore,
                addUnsubscribeStore,
                purchaseOrders,
                setPurchaseOrders
            }}
        >
            {children}
        </StoreContext.Provider>
    );
};
