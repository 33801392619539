/* eslint-disable no-restricted-globals */
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";

//Main Database
const firebaseConfig = {
    apiKey: "AIzaSyCiMxbHYjwMJIC4Zbmm9aPWNrqMG9Rc3JQ",
    authDomain: "nnc-app.firebaseapp.com",
    projectId: "nnc-app",
    storageBucket: "nnc-app.appspot.com",
    messagingSenderId: "136040687779",
    appId: "1:136040687779:web:d7167fdeaf9d9f23f5f716",
    measurementId: "G-60DC853291"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
export const dbRT = getDatabase();

if (location.hostname === "localhost1") {
    connectFirestoreEmulator(db, "localhost", 8080);
    connectFunctionsEmulator(functions, "localhost", 5001);
    connectAuthEmulator(auth, "http://localhost:9099");
    connectDatabaseEmulator(dbRT, "localhost", 9000);
}

export default app;
