import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
    Button,
    Chip,
    Divider,
    Grid,
    Icon,
    IconButton,
    InputAdornment,
    LinearProgress,
    TextField,
    Typography,
    styled,
    useTheme
} from "@mui/material";
import { blue, green, grey, orange, red } from "@mui/material/colors";
import { Box } from "@mui/system";
import { tokens } from "../theme";

export const GridContainer = (props) => {
    let {
        s1,
        s2,
        s3,
        spacing,
        flexDirection = "row",
        alignItems = "center",
        display = "flex",
        pr1,
        fs,
        bgColor,
        m1,
        pl1
    } = props;

    let s = 0;
    s = s1 ? 1 : s;
    s = s2 ? 2 : s;
    s = s3 ? 3 : s;

    if (spacing) s = spacing;

    let paddingLeft = 0;
    if (pl1) paddingLeft = "8px";

    let paddingRight = 0;
    if (pr1) paddingRight = "8px";

    let justifyContent = "center";
    if (fs) justifyContent = "flex-start";

    let margin = 0;
    if (m1) margin = 1;

    return (
        <Grid
            display={display}
            container
            spacing={s}
            justifyContent={justifyContent}
            alignItems={alignItems}
            flexDirection={flexDirection}
            margin={margin}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            sx={{ backgroundColor: bgColor }}
        >
            {props.children}
        </Grid>
    );
};

export const GridFlexBox = ({
    children,
    justifyContent = "center",
    xs,
    md,
    fs,
    fe,
    fw,
    sa, //space-around
    sb, //space-between
    gap = 0,
    m0,
    m1,
    m3,
    p0,
    p2,
    p5,
    w = "100%",
    overflow,
    fd,
    mt2,
    mt4,
    mt8,
    mtb1,
    mtb2,
    ml1,
    cp,
    order,
    bgcolor,
    style = {},
    onClick = () => {}
}) => {
    if (fs) justifyContent = "flex-start";
    if (fe) justifyContent = "flex-end";
    if (sa) justifyContent = "space-around";
    if (sb) justifyContent = "space-between";

    let flexWrap;
    if (fw) flexWrap = "wrap";

    let margin;
    let padding = 1;
    if (m0) margin = 0;
    if (m1) margin = 8;
    if (m3) margin = 24;
    if (p0) padding = 0;
    if (p2) padding = 2;
    if (p5) padding = 5;

    let marginTop = 1;
    if (mt2) marginTop = 2;
    if (mt4) marginTop = 4;
    if (mt8) marginTop = 8;

    let marginBottom = 1;
    if (mtb1) {
        marginTop = 8;
        marginBottom = 8;
    }

    let marginLeft = 0;
    if (ml1) {
        marginLeft = "8px";
    }

    if (mtb2) {
        marginTop = 8 * 2;
        marginBottom = 8 * 2;
    }

    let whiteSpace = "nowrap";
    if (overflow) whiteSpace = "normal";

    let cursor;
    if (cp) cursor = "pointer";

    return (
        <Grid
            item
            xs={xs}
            md={md}
            alignItems="center"
            order={order}
            style={{
                paddingTop: padding,
                paddingLeft: padding,
                marginLeft: marginLeft,
                marginTop: marginTop,
                marginBottom: marginBottom,
                width: w ? w : "auto",
                whiteSpace: whiteSpace,
                cursor: cursor
            }}
        >
            <Box
                display="flex"
                flexDirection={fd}
                flexWrap={flexWrap}
                justifyContent={justifyContent ? justifyContent : "center"}
                alignItems="center"
                gap={gap}
                onClick={onClick}
                style={{
                    ...style,
                    margin: margin,
                    backgroundColor: bgcolor
                }}
            >
                {children}
            </Box>
        </Grid>
    );
};

export const GridItem = (props) => {
    const { fe, fs, w = "100%", fs10, fs12, fs14, fs20, bold, blueColor, mt2, mt4, pl1 } = props;

    let color;
    if (blueColor) color = blue[700];
    return (
        <GridFlexBox fe={fe} fs={fs} w={w} mt2={mt2} mt4={mt4} pl1={pl1}>
            <Name fs10={fs10} fs12={fs12} fs14={fs14} fs20={fs20} bold={bold} color={color}>
                {props.children}
            </Name>
        </GridFlexBox>
    );
};

export const Title = ({ children, onClick = () => {}, cp, pl1 }) => {
    let cursor;
    if (cp) cursor = "pointer";
    let paddingLeft = 0;
    if (pl1) paddingLeft = "8px";

    return (
        <Typography variant="h5" onClick={onClick} style={{ cursor: cursor }} paddingLeft={paddingLeft}>
            {children}
        </Typography>
    );
};

export const Name = ({
    children,
    bold,
    ct,
    fs10,
    fs12,
    fs13,
    fs14,
    fs20,
    color,
    wrap,
    nowrap,
    mr = 0,
    ml1,
    ml3,
    ml5,
    onClick = () => {},
    cp,
    whiteSpace = "normal",
    pt1,
    pb1,
    pl1,
    pr1,
    bgcolor,
    fontFamily,
    style
}) => {
    // Font Size
    let fontSize;
    if (ct) fontSize = "0.8rem";
    if (fs10) fontSize = 10;
    if (fs12) fontSize = "12px";
    if (fs13) fontSize = 13;
    if (fs14) fontSize = "0.9rem";
    if (fs20) fontSize = 20;

    let fontWeight;
    if (bold) fontWeight = "bold";

    let noWrap = true;
    if (wrap) {
        whiteSpace = "normal";
        noWrap = false;
    }
    if (nowrap) {
        whiteSpace = "nowrap";
        noWrap = true;
    }

    let marginLeft = 0;
    if (ml1) marginLeft = 1;
    if (ml3) marginLeft = 3;
    if (ml5) marginLeft = 5;

    let paddingTop = 0;
    let paddingBottom = 0;
    let paddingLeft = 0;
    let paddingRight = 0;
    if (pt1) paddingTop = 1;
    if (pb1) paddingBottom = 1;
    if (pl1) paddingLeft = 1;
    if (pr1) paddingRight = 1;

    let cursor;
    if (cp) cursor = "pointer";

    return (
        <Typography
            variant="body2"
            noWrap={noWrap}
            marginRight={mr}
            marginLeft={marginLeft}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            fontWeight={fontWeight}
            onClick={onClick}
            style={{
                fontSize: fontSize,
                color: color,
                whiteSpace: whiteSpace,
                overflowWrap: "break-word",
                cursor: cursor,
                backgroundColor: bgcolor,
                fontFamily: fontFamily,
                ...style
            }}
        >
            {children}
        </Typography>
    );
};

export const GridDivider = (props) => {
    const { ptb = 10, plr = 2 } = props;

    return (
        <Grid
            item
            xs={12}
            style={{
                paddingTop: ptb,
                paddingLeft: plr,
                paddingRight: plr,
                paddingBottom: ptb
            }}
        >
            <Divider />
        </Grid>
    );
};

export const IconC = (props) => {
    let { color, onClick, cp, style, display, big, fs10, fs12, fs14, fs16, fs30 } = props;
    let cursor;
    let fontSize = "small";
    if (big) fontSize = "big";

    let fs;
    if (fs10) fs = 10;
    if (fs12) fs = 12;
    if (fs14) fs = 14;
    if (fs16) fs = 16;
    if (fs30) fs = 30;
    if (cp) cursor = "pointer";
    if (!style)
        style = {
            color: color,
            cursor: cursor,
            display: display,
            fontSize: fs
        };
    return (
        <Icon fontSize={fontSize} style={style} onClick={onClick}>
            {props.children ? props.children : "account_circle"}
        </Icon>
    );
};

export const IconB = (props) => {
    const { icon, onClick = () => {} } = props;
    return (
        <IconButton onClick={() => onClick(props.children)}>
            <Icon>{icon ? icon : "account_circle"}</Icon>
            <Name fs10 ml1>
                {props.children}
            </Name>
        </IconButton>
    );
};

export const Loading = ({ loading }) => {
    return (
        <Grid item xs={12}>
            {loading && <LinearProgress />}
        </Grid>
    );
};

export const TextFieldC = ({
    onChange = () => {},
    value,
    name,
    id,
    label,
    small,
    date,
    shrinkLabel,
    password,
    passwordAF, //password autofill
    email,
    type = "text",
    fullWidth = true,
    required = true,
    autoFocus = true,
    inputProps,
    w = "100%"
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const CssTextField = styled(TextField)({
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: colors.greenAccent[100]
            }
        }
    });

    let size = "medium";
    if (small) size = "small";
    if (date) type = "date";
    if (passwordAF) type = "password"; // if use type = "password" chrome will autofill the password

    let webkitTextSecurity;
    if (password) {
        webkitTextSecurity = "disc";
    }

    if (email) type = "email";
    return (
        <GridFlexBox mtb1 w={w}>
            <CssTextField
                size={size}
                autoFocus={autoFocus}
                name={name}
                id={id || name}
                type={type}
                value={value}
                label={label}
                required={required}
                fullWidth={fullWidth}
                onChange={onChange}
                InputLabelProps={{
                    shrink: date || shrinkLabel,
                    style: {
                        WebkitTextSecurity: "none",
                        color: colors.primary[100]
                    }
                }}
                style={{ WebkitTextSecurity: webkitTextSecurity }}
                InputProps={inputProps}
            />
        </GridFlexBox>
    );
};

// TextField with Input Adornment
export const TextFieldIA = (props) => {
    const {
        name,
        id,
        required = true,
        size,
        fullWidth = true,
        label,
        type = "text",
        value,
        handleClick,
        icon = "face"
    } = props;
    return (
        <TextField
            name={name}
            id={id || name}
            required={required}
            size={size || "small"}
            fullWidth={fullWidth}
            label={label}
            type={type}
            value={value}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" onClick={handleClick}>
                        <Icon
                            style={{
                                cursor: "pointer"
                            }}
                        >
                            {icon}
                        </Icon>
                    </InputAdornment>
                ),
                onKeyDown: (event) => {
                    event.preventDefault();
                }
            }}
        />
    );
};

export const ButtonC = ({
    children,
    bgColor,
    small,
    p0,
    fs10,
    disabled = false,
    color,
    fullWidth,
    submit,
    mtb1,
    ml1,
    onClick = () => {}
}) => {
    let padding;
    if (p0) padding = 0;
    let size;
    if (small) size = "small";

    let fontSize;
    if (fs10) fontSize = 10;

    let type = "button";
    if (submit) type = "submit";

    let marginTop = 1;
    let marginBottom = 1;
    if (mtb1) {
        marginTop = 8;
        marginBottom = 8;
    }

    let marginLeft = 0;
    if (ml1) marginLeft = "8px";

    return (
        <Button
            type={type}
            fullWidth={fullWidth}
            color={color}
            size={size}
            variant="contained"
            onClick={onClick}
            disabled={disabled}
            style={{
                marginTop: marginTop,
                marginBottom: marginBottom,
                marginRight: "8px",
                backgroundColor: bgColor,
                marginLeft: marginLeft,
                paddingTop: padding,
                paddingBottom: padding,
                fontSize: fontSize,
                color: "white"
            }}
        >
            {children}
        </Button>
    );
};

export const ChartC = (props) => {
    return (
        <Typography
            noWrap
            fontSize={"small"}
            style={{
                marginLeft: -2,
                color: blue[500],
                fontSize: "10px"
            }}
        >
            {props.children}
        </Typography>
    );
};

export const Status = (props) => {
    let { pending, cp, onClick = () => {}, fs12, fs14, greyed } = props;

    let fontSize = "10px";
    if (fs12) fontSize = "12px";
    if (fs14) fontSize = "14px";

    let backgroundColor = blue[500];
    if (pending) backgroundColor = orange[500];
    if (greyed) backgroundColor = grey[500];

    let cursor;
    if (cp) cursor = "pointer";
    return (
        <Typography
            border="solid 1px"
            borderRadius="7px"
            fontSize={fontSize}
            textAlign="right"
            marginLeft="5px"
            marginTop="3px"
            paddingLeft="5px"
            paddingRight="5px"
            color="whitesmoke"
            backgroundColor={backgroundColor}
            style={{ cursor: cursor }}
            onClick={onClick}
        >
            {props.children}
        </Typography>
    );
};

export const BoxC = (props) => {
    const { w, mt0, mt1, mb0, mb1, onClick, cp } = props;

    let marginTop = 0;
    let marginBottom = 0;
    let marginLeft = 1;
    let marginRight = 1;
    if (mt0) marginTop = 0;
    if (mt1) marginTop = 1;
    if (mb0) marginBottom = 0;
    if (mb1) marginBottom = 1;

    let cursor;
    if (cp) cursor = "pointer";

    return (
        <Box
            sx={{ width: w, cursor: cursor }}
            flexShrink={0}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            onClick={onClick}
            alignItems={"center"}
        >
            {props.children}
        </Box>
    );
};

export const Label = ({ children, label = { color: blue[500], fontColor: grey[100] }, onClick, fontSize = 9 }) => {
    if (!children) return "";
    return (
        <IconButton onClick={onClick} style={{ padding: 0 }}>
            <Typography
                style={{
                    fontSize: fontSize,
                    border: "1px solid",
                    borderRadius: 7,
                    paddingLeft: 7,
                    paddingRight: 7,
                    marginLeft: 3,
                    backgroundColor: label.color,
                    color: label.fontColor,
                    borderColor: label.color
                }}
            >
                {children}
            </Typography>
        </IconButton>
    );
};

export const IconButtonC = (props) => {
    const { onClick, size = "medium", color } = props;
    return (
        <IconButton style={{ padding: 0 }} size={"small"} onClick={onClick}>
            <Icon fontSize={size} style={{ color: color }}>
                {props.children}
            </Icon>
        </IconButton>
    );
};

export const ChipC = ({ color, children, size = "small", bgcolor = blue[500], fontColor = grey[100] }) => {
    if (color === "red") {
        bgcolor = red[500];
    }
    if (color === "blue") {
        bgcolor = blue[500];
    }
    if (color === "orange") {
        bgcolor = orange[500];
    }
    if (color === "green") {
        bgcolor = green[500];
    }
    if (color === "grey") {
        bgcolor = grey[500];
    }
    return (
        <Chip
            label={children}
            style={{
                backgroundColor: bgcolor,
                color: fontColor,
                marginRight: "2px"
            }}
            size={size}
        />
    );
};

export function TextFieldDate({ value, onChange = () => {} }) {
    return (
        <TextField
            autoFocus={false}
            fullWidth
            type="datetime-local"
            onChange={onChange}
            value={value}
            InputLabelProps={{
                shrink: true
            }}
        />
    );
}

export const SelectItem = ({ value, label = "label", onClick = () => {} }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <GridContainer m1>
            <GridFlexBox fs>
                <Name fs14>{label}</Name>
            </GridFlexBox>
            <Grid item xs={12}>
                <Box
                    border="1px solid"
                    borderRadius={"10px"}
                    borderColor={colors.grey[800]}
                    width={"100%"}
                    height={"50px"}
                    style={{ cursor: "pointer" }}
                    p={1}
                    display="flex"
                    alignItems="center"
                    onClick={onClick}
                >
                    <GridFlexBox xs={11} fs>
                        {value}
                    </GridFlexBox>
                    <GridFlexBox xs={1} fe>
                        <ArrowDropDownIcon />
                    </GridFlexBox>
                </Box>
            </Grid>
        </GridContainer>
    );
};
