import { useEffect, useRef, useState } from "react";
import { GridContainer, GridFlexBox, Name } from "../../themes/themes";
import { Paper } from "@mui/material";

export default function TypingEffect({ sentences = [], setDone = () => {}, speed = 1 }) {
    const [typing, setTyping] = useState("");
    const [index, setIndex] = useState(0);
    const [array, setArray] = useState([false]);

    const ref = useRef();

    useEffect(() => {
        ref.current.scrollTo({ top: ref.current.scrollHeight, behavior: "smooth" });
    }, [typing, array]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setTyping(sentences[index]?.slice(0, typing.length + 3) || "");
        }, speed);

        if (typing === sentences[index] && index < sentences.length - 1) {
            setArray((array) => {
                const newArray = [...array];
                newArray[index] = true;
                return [...newArray, false];
            });
            setIndex((index) => index + 1);
            setTyping("");
        }

        if (array.length === sentences.length && typing === sentences[sentences.length - 1]) {
            setDone(true);
        }

        return () => clearTimeout(timeout);
    }, [typing, index, array, sentences]);

    return (
        <GridContainer>
            <GridFlexBox>
                <Paper elevation={2} style={{ width: "100%", height: "22vh" }}>
                    <div
                        style={{
                            width: "100%",
                            height: "20vh",
                            overflow: "auto",
                            padding: "8px",
                            backgroundColor: "transparent"
                        }}
                        ref={ref}
                    >
                        {array.map((done, index) => (
                            <div key={index}>
                                <Name fontFamily={"Inconsolata"}> {!done ? typing : sentences[index]}</Name>
                            </div>
                        ))}
                    </div>
                </Paper>
            </GridFlexBox>
        </GridContainer>
    );
}
