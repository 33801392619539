import React, { useContext, useEffect, useState } from "react";
import isAdmin from "../../functions/common-functions/isAdmin";

import { InputContext } from "../../context-utils/InputContext";
import { AuthContext } from "../../context/AuthContext";
import { GridDivider, GridFlexBox, IconC, Name } from "../../themes/themes";
import editField from "../../functions/common-functions/editField";

const KEYS = {
    displayName: "Display Name",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    role: "Role",
};

export default function DataFields({ object, collectionId, keys_ = KEYS, handleUpdate }) {
    const [keys, setKeys] = useState(keys_);
    const { user } = useContext(AuthContext);

    const { openInputDialog } = useContext(InputContext);

    useEffect(() => {
        if (object) {
            let newKeys = {};
            Object.keys(keys_).forEach((key) => {
                newKeys[key] = keys[key];
            });
            let extraKeys = {};

            if (user.role === "Super Admin") {
                Object.keys(object).forEach((key) => {
                    if (checkString(object, key)) {
                        if (!newKeys[key]) {
                            extraKeys[key] = key;
                        }
                    }
                });
            }

            setKeys({ ...newKeys, ...extraKeys });
        }
    }, [object]);

    const handleClick = async (key) => {
        const response = await openInputDialog("Edit field", "Edit " + key, "", checkString(object, key));
        await editField(object, key, response, collectionId);
        handleUpdate(object, key, response);
    };

    return (
        <React.Fragment>
            {object &&
                Object.keys(keys).map((key) => (
                    <React.Fragment key={key}>
                        <GridFlexBox w="30%" fs>
                            <Name>{keys[key]}:</Name>
                        </GridFlexBox>
                        <GridFlexBox w="60%" fs>
                            <Name whiteSpace="nowrap">{checkString(object, key) || ""}</Name>
                        </GridFlexBox>
                        <GridFlexBox w="10%" fe>
                            {isAdmin(user) && <IconC onClick={() => handleClick(key)}>edit</IconC>}
                        </GridFlexBox>
                    </React.Fragment>
                ))}
            <GridDivider />
        </React.Fragment>
    );
}

const checkString = (object, key) => {
    if (typeof object[key] === "string") {
        return object[key];
    } else {
        return "";
    }
};
