import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../functions/common-functions/mapSnapshot";

export default function loadPurchaseOrders(setPurchaseOrders) {
    try {
        const collectionRef = collection(db, "PurchaseOrders");
        return onSnapshot(collectionRef, (snapshot) => {
            const purchaseOrders = mapSnapshot(snapshot);
            setPurchaseOrders(purchaseOrders);
            console.log(purchaseOrders);
        });
    } catch (error) {
        console.log(error);
    }
}
